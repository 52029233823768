<template>
  <div class="d-flex content-center min-vh-100">
    <BContainer>
      <BRow class="justify-content-center">
        <BCol md="10">
          <BCard class="text-left"  footer-tag="footer" footer-bg-variant="light">
            <div slot="header">
              <strong>Xpixel 업그레이드</strong>
              <div class="card-header-actions">
                <small class="text-muted">Xpixel을 업그레이드 구매합니다.</small>
              </div>
            </div>


            <BCard v-if="salesInfo">
              <div slot="header">
                <strong>{{salesInfo.name}}</strong>
              </div>
              <strong v-html="salesInfo.title"></strong>
              <BImg :src="imgSrc" fluid-grow/>
              <BRow>
                <BCol>
                  <p v-html="salesInfo.content"></p>
                </BCol>
              </BRow>

              <BTable selectable hover bordered
                      :items="products"
                      :fields="prdFields"
                      thead-class="text-center"
                      selectedVariant="primary"
                      ref="productTable"
                      id="productTable"
                      @row-selected="productSelected"
                      select-mode="multi">
                <template v-slot:cell(selected)="data">
                  <BBadge v-show="data.rowSelected" variant="danger">선택</BBadge>
                </template>

              </BTable>

            </BCard>




            <BRow>
              <BCol v-if="orderEnabled">
                <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                  <BForm ref="orderFrom" @submit.stop.prevent="handleSubmit(onSubmit)">
                    <validation-provider  :rules="{required:true, min:3}" name="아이디" v-slot="validationContext">
                      <BFormText id="userid-help">XCOST.ME 회원을 확인합니다. <b class="text-danger">비회원</b>의 경우 <strong class="bg-info border">신규가입</strong>을 클릭하세요</BFormText>
                      <BInputGroup  class="mb-3">
                        <BInputGroupAppend is-text><BIconPersonCheckFill/></BInputGroupAppend>
                        <BFormInput v-model="userId"
                                    ref="userId"
                                    name="user_id"
                                    :state="getValidationState(validationContext)"
                                    placeholder="아이디 입력"
                                    aria-describedby="userid-help userid-feedback"
                                    autofocus
                                    :disabled="isUserVerified"
                        />
                        <BInputGroupAppend is-text>
                          <BIconKeyFill/>
                        </BInputGroupAppend>
                        <BFormInput v-model="userPwd" ref="userPwd"
                                    placeholder="비밀번호" name="userPwd" type="password" :disabled="isUserVerified || !userId" required/>
                        <BInputGroupAppend>
                          <BButton variant="success"
                                   @click="verifyUser"
                                   :disabled="!getValidationState(validationContext) || isUserVerified || !userPwd ">
                            <BSpinner small v-show="isVerifying"></BSpinner>
                            회원확인
                          </BButton>
                        </BInputGroupAppend>
                        <BInputGroupAppend>
                          <BButton variant="info" class="ml-1"
                                   @click="moveToCreateAccount"
                                   v-show="isUserVerified===false"
                                   :disabled="isUserVerified || isAccountRegistered">
                            신규가입
                          </BButton>
                        </BInputGroupAppend>
                        <BFormInvalidFeedback id="userid-feedback" class="text-left">
                          {{ validationContext.errors[0] }}
                        </BFormInvalidFeedback>
                      </BInputGroup>
                    </validation-provider>


                    <validation-provider name="email" rules="required|email" v-slot="validationContext">
                      <BInputGroup  class="mb-3" id="order-email">
                        <BInputGroupPrepend is-text><BIconAt/></BInputGroupPrepend>
                        <BFormInput v-model="order.email" type="email" ref="emailInput"
                                    :state="getValidationState(validationContext)"
                                    placeholder="이메일 주소"
                                    autocomplete="email"
                                    aria-describedby="email-feedback"
                                    :disabled="isEmailVerified || !isUserVerified"/>
                        <BInputGroupAppend>
                          <BButton variant="warning" @click="sendAuthEmail" :disabled="!getValidationState(validationContext) || isEmailVerified">
                            <BSpinner small v-show="isEmailSending"></BSpinner>인증번호 전송
                          </BButton>
                        </BInputGroupAppend>
                        <BFormInvalidFeedback id="email-feedback" class="text-left">
                          {{ validationContext.errors[0] }}
                        </BFormInvalidFeedback>
                        <BFormValidFeedback>{{emailValidCheckText}}</BFormValidFeedback>
                      </BInputGroup>
                    </validation-provider>

                    <validation-provider name="인증번호" :rules="{required:true, min:5}" v-slot="validationContext">
                      <BInputGroup  class="mb-3" >
                        <BInputGroupPrepend is-text><BIconUnlockFill/></BInputGroupPrepend>
                        <BFormInput v-model="order.auth_no" type="number"
                                    placeholder="이메일로 전송된 인증번호 입력"
                                    :state="getValidationState(validationContext)"
                                    aria-describedby="pwd-feedback"
                                    :disabled="!isEmailVerified"/>
                        <BFormInvalidFeedback id="pwd-feedback" class="text-left">
                          {{ validationContext.errors[0] }}
                        </BFormInvalidFeedback>
                        <BFormValidFeedback>신청 등록시 인증번호를 확인합니다.</BFormValidFeedback>
                      </BInputGroup>
                    </validation-provider>

                    <validation-provider name="제품키" :rules="{required:true, min:25}" v-slot="validationContext">
                      <BInputGroup  class="mb-3" >
                        <BInputGroupPrepend is-text><BIconKeyFill/></BInputGroupPrepend>
                        <BFormInput v-model="order.prd_key" type="text"
                                    placeholder="Xpixel-I 제품키 입력 (대소문자 구분 없음)"
                                    aria-describedby="pwd-feedback"
                                    :state="getValidationState(validationContext)"
                                    />
                        <BFormInvalidFeedback id="key-feedback" class="text-left">
                          {{ validationContext.errors[0] }}
                        </BFormInvalidFeedback>
                        <BFormValidFeedback>등록시 제품키를 확인합니다.</BFormValidFeedback>
                      </BInputGroup>
                    </validation-provider>

                    <BInputGroup  class="mb-3">
                      <BInputGroupAppend is-text>금 액</BInputGroupAppend>
                      <BFormInput class="text-right" v-model="orderAmountTxt" readonly/>
                      <BInputGroupAppend is-text>총 금액</BInputGroupAppend>
                      <BFormInput class="text-right font-weight-bold bg-warning" v-model="totalAmountTxt" readonly/>
                    </BInputGroup>

                    <BInputGroup  class="mb-3">
                      <BInputGroupPrepend is-text><BIconPersonCircle/></BInputGroupPrepend>
                      <BFormInput v-model="order.name" placeholder="이름" autocomplete="name" required/>
                    </BInputGroup>

                    <BInputGroup  class="mb-3">
                      <BInputGroupPrepend is-text><BIconTelephone/></BInputGroupPrepend>
                      <BFormInput v-model="order.phone_no" placeholder="휴대폰번호 또는 전화번호" autocomplete="phone_no" required/>
                    </BInputGroup>
                    <BInputGroup  class="mb-3" v-if="false">
                      <BInputGroupPrepend is-text><BIconPencilFill/></BInputGroupPrepend>
                      <BFormInput v-model="order.description" placeholder="요청사항 및 메모 입력" autocomplete="description"/>
                    </BInputGroup>

                    <BInputGroup  class="mb-2">
                      <BInputGroupPrepend is-text><BIconUiChecksGrid/></BInputGroupPrepend>
                      <BFormSelect required v-model="order.tax_stat" :options="taxStatOpts"/>
                    </BInputGroup>

                    <BCollapse id="compInfo" v-model="order.tax_stat==='01'">
                      <BCard class="m-0" border-variant="primary" header="primary" header-bg-variant="primary" header-text-variant="white" >

                        <div slot="header">
                          <strong>사업자 등록 정보</strong>
                          <div class="card-header-actions">
                            <small>계산서 발행을 위해 정확하게 기재해 주시기 바랍니다.</small>
                          </div>
                        </div>

                        <BInputGroup size="sm" class="mb-2">
                          <BInputGroupPrepend is-text><BIconCreditCard2Front/></BInputGroupPrepend>
                          <BFormInput v-model="order.comp_name" type="text" placeholder="회사명/상호" autocomplete="comp_name" :required="order.tax_stat==='01'"/>
                        </BInputGroup>

                        <BInputGroup size="sm" class="mb-2">
                          <BInputGroupPrepend is-text><BIconCreditCard2Front/></BInputGroupPrepend>
                          <BFormInput v-model="order.pr_name" type="text" placeholder="대표자명" autocomplete="pr_name" :required="order.tax_stat==='01'"/>
                        </BInputGroup>

                        <BInputGroup size="sm" class="mb-2">
                          <BInputGroupPrepend is-text><BIconCardList/></BInputGroupPrepend>
                          <BFormInput v-model="order.biz_kind" type="text" placeholder="업태/업종 입력 예) 건설업/실내건축공사업" autocomplete="biz_kind" :required="order.tax_stat==='01'"/>
                        </BInputGroup>

                        <BInputGroup  class="mb-2">
                          <BInputGroupPrepend is-text><BIconBuilding/></BInputGroupPrepend>
                          <BFormInput v-model="order.comp_no" type="text" maxlength="10" placeholder="사업자 번호" :state="compnoState" autocomplete="comp_no" :required="order.tax_stat==='01'"/>
                        </BInputGroup>

                        <BInputGroup size="sm" class="mb-2">
                          <BInputGroupPrepend is-text><BIconMap/></BInputGroupPrepend>
                          <BFormInput v-model="order.address" placeholder="사업장 소재지" autocomplete="address" :required="order.tax_stat==='01'"/>
                        </BInputGroup>
                      </BCard>
                    </BCollapse>

                    <BRow class="mt-5">
                      <BCol class="text-center">
                        <BButtonGroup>
                          <BButton type="submit" variant="success" class="mr-2">
                            <BSpinner small v-show="isSubmiting"></BSpinner>
                            신청등록
                          </BButton>
                          <BButton type="button" variant="danger" @click="resetForm"> reset</BButton>
                        </BButtonGroup>
                      </BCol>
                    </BRow>

                  </BForm>
                </ValidationObserver>
              </BCol>
            </BRow>

            <div slot="footer">
              <div class="small text-muted">
                <strong><i class="fa fa-copyright"></i> <a href='http://qqkorea.net'>(주)하우코스트</a></strong>
                <span class="hidden-xs en">All rights reserved.</span><br>
                <span class="hidden-xs en">
                                    대표자 : 유현오 | E-Mail : <a href="mailto:estimate@howcost.co.kr">estimate@howcost.co.kr</a>) <br>
                                    <a href='http://xcost.me/bbs/qalist.php'>적산문의/프로그램/문의 1:1상담</a> | tel. 031-417-0466 <br/>
                                    사무실 : 경기도 수원시 권선구 수성로 8 경기중소기업성장지원센터 303호 <br/>부설연구소 : 건설계약연구소/벤쳐기업인증/연구개발서비스업등록/소프트웨어개발업/ISO9001,ISO14001인증<br/>
                                    사업자번호 : 809-86-00250</span>
              </div>
            </div>
          </BCard>

        </BCol>
      </BRow>


    </BContainer>

    <b-modal id="termsModal" size="xl" scrollable title="이용약관">
      <BFormTextarea
          v-model="termsText"
          rows="100"
          max-rows="100"
          disabled
      ></BFormTextarea>
    </b-modal>


  </div>
</template>

<script>
import {
  apiCall,
  cloneVar,
  alertError,
  alertWarn,
  alertConfirm,
  alertSuccess,
  checkBisNo,
  commify
} from '../../../common/utils';
import terms from "../user-order/_terms";
import DaumPostcode from 'vuejs-daum-postcode';
import moment from "moment";

const _order = {
  prd_code     : null,
  sales_code   : null,
  sales_master : null,
  products     : null,
  order_type   : 'UPGRADE',
  prd_key      : null,  // 업그레이트 체크용 제품키 필드 추가
  dept_code    : null,
  unit_price   : null,
  qty          : 1,
  warranty_mon : null,
  price        : null,
  pay_amt      : null,
  pay_type     : null,
  cu_seq       : null,
  cu_id        : null,
  ord_dt       : null,
  email        : '',
  name         : null,
  phone_no     : null,
  comp_no      : null,
  comp_name    : null,
  pr_name      : null,
  address      : null,
  zip_code     : null,
  appr_yn      : null,
  appr_dt      : null,
  send_stat    : null,
  send_dt      : null,
  pay_stat     : null,
  pay_dt       : null,
  tax_stat     : null,
  tax_dt       : null,
  tax_seq      : null,
  biz_kind     : null,
  cncl_yn      : null,
  cncl_dt      : null,
  cncl_desc    : null,
  description  : '',
  reg_dt       : null,
  upd_dt       : null,
  reg_id       : null,
  upd_id       : null,
  agreement    : null,
  auth_no      : null,
  addr_detail  : '',
  auth_seq     : null
};


export default {
  name: 'UpgradeOrder',
  components: {
    DaumPostcode
  },
  props: ['userToken', 'salesCode', 'newUserId'],
  data () {
    return {
      userId: '',
      orderItems : [],
      orderAmountTxt: '',
      totalAmountTxt: '',
      userPwd: '',
      agreeEmail : 'Y',
      agreeSms : 'Y',
      isIdChecked: false,
      isPwdChecked: false,
      isEmailValid: false,
      isEmailChecked: false,
      newAccountBtnClicked: false,
      newAccountBtnShow: true,
      isAccountRegistered: false,
      order: cloneVar(_order),
      isEmailVerified: false,
      isUserVerified: false,
      isVerifying: false,
      termsText: terms[0].text,
      agreement: 'Y',
      emailValidCheckText: '이메일 형식입니다 (인증번호 전송버튼을 클릭하세요)',
      taxStatOpts: [
        { value: null, text: '개인/사업자 선택' },
        { value: '03', text: '개인' },
        { value: '01', text: '사업자' },
      ],
      isSubmiting: false,
      isEmailSending:false,
      products: [],
      prdInfo: null,
      salesInfo: null,
      imgSrc: null,
      orderAmount: '',
      totalPrice: '',
      orderEnabled: false,
      prdCodeOpts: [
        { text:'1개(1+1)', value:'2106-0002' },
        { text:'1개', value:'2106-0001' },
      ],
      // pwdRegexp: /^(?=.*[0-9]+)[a-zA-Z][a-zA-Z0-9]{7,29}$/, // 영어+숫자(8자이상)
      pwdRegexp: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/, // 최소8자 영문,숫자,특수문자 조합
      prdFields: [
        {key:'prd_code', label: '제품코드' },
        {key:'disp_name', label: '제품명' },
        {key:'warranty_mon', label: '사용기간(월)', tdClass: 'text-right mr-2' },
        {key:'unit_price', label: '금액', tdClass: 'text-right mr-2', formatter: v=>{  return commify(v)}},
        {key:'selected', label: '선택', tdClass: 'text-center' },
      ]

    }
  },
  computed: {
    compnoState: function(){
      return checkBisNo(this.order.comp_no);
    },

    validateEmail() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.order.email)) {
        this.isEmailValid = true;
        return true;
      } else {
        this.isEmailValid = false;
        return false;
      }
    },

    validPwdState(){
      let ret = false;
      // const p2 = this.newPwdConfirm;
      if( this.newPwd.length > 7 ){
        if( this.pwdRegexp.test( this.newPwd )) ret = true;
      }
      return ret;
    },

  },

  async created(){

    if( this.newUserId ) this.userId = this.newUserId;
    console.log("--created: SubscriptionForm ----- prdCode====>",this.salesCode, );
    console.log("--created: SubscriptionForm ----- userId====>",this.userId, );
    if( !this.userId ) this.userId = '';


    try{

      await this.getSalesMasterInfo();
      // await this.getPrdInfo();

      const pubDay = moment(this.salesInfo.pubDt).format("YYYY-MM-DD");
      const endDay = moment(this.salesInfo.endDt).format("YYYY-MM-DD");
      const toDay = moment().format("YYYY-MM-DD");
      // console.log( "pubDay, endDay, toDay ----- ", pubDay, endDay, toDay );
      if( toDay < pubDay || toDay > endDay)
        this.orderEnabled = false;
      else
        this.orderEnabled = true;


    }catch(err){
      console.log( err  );

    }
  },
  beforeMount() { },

  mounted(){
    console.log("-------- mounted: OrderForm ----- prdCode : " + this.prdCode );
    if(this.userToken){
      this.getUserInfo();
    }
  },

  methods: {

    productSelected(items){
      // this.$refs.productTable.isRowSelected()
      this.orderAmount = 0;

      items.map(i=>{
        i.selected = true
        this.orderAmount += i.unit_price;
      });
      this.orderItems = items;
      this.orderAmountTxt = commify(this.orderAmount) + ' 원';
      this.totalAmountTxt = commify(this.orderAmount) + ' 원';

    },
    async moveToCreateAccount(){
      try{
        await this.$router.push({
          name:'MemberRegister',
          params: {
            passValue: {
              fromView: 'MultiOrder',
              salesCode: this.salesCode
            }
          },
        });

      }catch(err){
        console.log(err)
      }
    },

    newAccountToggle(){
      this.newAccountBtnClicked = !this.newAccountBtnClicked;
    },

    resetNewId(){

    },

    async verifyUser(){
      console.log( "verifyUser ---- "+this.userId );

      try{
        this.isVerifying = true;
        const r = await apiCall('post', `/api/member/xcost`, {userId: this.userId, userPwd: this.userPwd});
        console.log( "result ################## ",r );
        if(r.result){
          const xcUser = r.result.userInfo;
          this.order.email = xcUser.email;
          this.order.name = xcUser.name;
          this.order.phone_no = xcUser.hpNo;
          this.order.cu_id = xcUser.userId;
          this.order.cu_seq = xcUser.userNo;
          this.order['accessToken'] = r.result.accessToken;
          this.isUserVerified = true;
          this.$nextTick(() => {
            this.$refs['emailInput'].focus();
            this.$refs['emailInput'].blur();
          });

          this.newAccountBtnClicked = false;
          this.newAccountBtnShow = false;

          if( xcUser.level==='P1' && moment(xcUser.lvEndDt).isAfter() && this.prdCode==='PRIM-0002' ){
            // 기존 P1 맴버십, 만료일이 지나지 않았으면...
            await alertSuccess(this.$bvModal,
                "보유하신 프리미엄 맵버십이 업그레이드 됩니다."
                +" 기존 맴버십의 잔여 일수를 금액으로 환산하여 프리미엄 플러스 맴버십의 만료일이 증가됩니다",
                "맴버십 업그레이드");
          }

        }else{
          this.isUserVerified = false;
          await alertWarn(this.$bvModal, "아이디 또는 비밀번호를 확인하세요", "인증 실패");
        }
      }catch(err){
        console.log(err);

      }finally{
        this.isVerifying = false;
      }
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    async getSalesMasterInfo(){
      console.log( "getSalesMasterInfo --->", this.salesCode );

      try{

        const r = await apiCall('get', `/api/sales/master/${this.salesCode}`);
        console.log( r );

        if(r.code===200){
          this.salesInfo = r.result;
          this.imgSrc = $baseURL + "/images/" + this.salesInfo.image;
          await this.getProducts( this.salesInfo.products );

        }else{
          await alertWarn( this.$bvModal, `잘못된 접근입니다`, 'Not Found' );
          return;
        }
      }catch(err){
        console.log(err);
      }
    },


    async getProducts( prods ){
      console.log( "getPrducts --->", prods );

      try{
        let r;
        for( let prd of prods ){
          console.log(prd);
          r = await apiCall('get', `/api/order/prd-info/${prd.prd_code}`);

          console.log( 'getProducts -------->', r );
          if(r.result.length){
            let prd = r.result[0];
            prd['selected'] = false;
            this.products.push( prd );
          }else{
            await alertWarn( this.$bvModal, `${prd.prd_code} 상품정보가 없습니다.`, 'Not Found' );
          }

        }

      }catch(err){
        console.log(err);
      }
    },



    resetForm() {
      this.order = cloneVar(_order);
      this.$refs['productTable'].clearSelected();
      this.isIdChecked = false;
      this.isPwdChecked = false;
      this.isEmailChecked = false;
      this.isAccountRegistered = false;
      this.isUserVerified = false;
      this.isEmailVerified = false;
      this.userId = '';
      this.userPwd = '';
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });

    },

    async sendAuthEmail(){
      try{
        const ret = await alertConfirm(this.$bvModal, `${this.order.email}로 인증번호를 전송합니다.`, '이메일 전송' );
        if(!ret){
          return;
        }
        //console.log("sendAuthEmail ------------- order:", this.order);

        this.isEmailSending = true;
        const r = await apiCall('post', '/api/order/send-auth-email', this.order);
        //console.log( "sendAuthEmail", r );
        if( r.code===200 ) {
          if (r.result) {
            this.isEmailVerified = true;
            this.emailValidCheckText = '이메일을 전송하였습니다. 인증번호를 입력하세요';
            this.order.auth_seq = r.result.auth_seq;
          }else {
            this.isEmailVerified = false;
            await alertError(this.$bvModal, "이메일을 확인하신후 재인증 하세요");
            return;
          }
        }else{
          await alertError(this.$bvModal, "이메일 전송실패", r.code);
        }
      }catch(err){
        console.log(err);
      }finally{
        this.isEmailSending = false;
      }

    },

    async getUserInfo(){
      console.log( "getUserInfo ---> ", this.userToken);
      try{
        const r = await apiCall('post', `/admin/auth/xcost/user`, {userToken: this.userToken});
        if(!r.result){
          await alertWarn( this.$bvModal, r.message, '사용자 확인 에러' );
          return;
        }

        console.log( "getUserInfo ----> result :" , r.result );

        const user = r.result;
        this.order.email = user.mb_email;
        this.order.name = user.mb_name;

        // console.log( "refs_email ====>", this.$refs["emailInput"] );

        if(user.mb_email){
          // this.$refs['email'].focus();
          this.$nextTick(function () {
            // this.$refs.emailInput.blur();
            this.$refs.emailInput.focus();
          });
        }

        await alertSuccess( this.$bvModal, "사용자 확인 성공", "사용자 확인");

        // console.log( r.result );

      }catch(err){
        console.log( err );
      }
    },

    async onSubmit(evt){
      console.log( "-------onSubmit---------" );

      try{

        if( this.orderItems.length===0 ){
          await alertWarn( this.$bvModal, `주문할 제품을 선택 하세요`, '주문제품 선택' );
          return;
        }

        if( this.order.tax_stat==='01' && (this.order.comp_no) ){
          this.order.comp_no  = this.order.comp_no.replace(/[^0-9]/g,"");

          if(!checkBisNo( this.order.comp_no )){
            await alertWarn( this.$bvModal, `사업자번호가 검증 실패`, '사업자번호 체크' );
            return;
          }
        }

        if(!this.isEmailVerified){
          await alertWarn(this.$bvModal, "인증번호 전송된 인증번호를 입력하세요", "인증번호 전송");
          return;
        }


        this.isSubmiting = true;
        this.order.sales_code = this.salesCode;
        this.order.products = this.orderItems;
        this.order.sales_master = this.salesInfo._id;

        const r = await apiCall('post', `/api/sales/confirm`, this.order);
        //console.log( "onSubmit ----------------> result: ", r );
        if(r.code===200){
          // await alertSuccess(this.$bvModal, '접수 되었습니다. 주문 내용은 이메일을 확인하시기 바랍니다.');
          const orderToken = r.result.orderToken;
          await this.$router.push( {name:"MultiOrderDetail", params: {orderToken: orderToken} } );
        }else{
          await alertError(this.$bvModal, `[ERROR] ${r.message}`);
        }
        this.isSubmiting = false;

      }catch(err){
        console.log(err);
      }finally{
        this.isSubmiting = false;
      }
    },
  },

}
</script>
